import { GeoState, SelectedGeo } from '@vakantiesnl/services/src/hooks/queries';
import { initBaseSearchReqParams } from '@vakantiesnl/services/src/search/initSearchRequest';
import { FilterState } from '@vakantiesnl/services/src/stores/filtersStore';
import { GlobalFiltersState } from '@vakantiesnl/services/src/stores/globalFiltersStore';
import { Search } from '@vakantiesnl/types';

import { getOnlySelectedGeo } from './getOnlySelectedGeo';

export const initZooverCompositeSearchReqParams = (
	filters: FilterState,
	globalFilters: GlobalFiltersState,
	geo: GeoState,
): Search.ZooverCompositeSearchBody => {
	const searchData = initBaseSearchReqParams(filters, globalFilters) as Search.ZooverCompositeSearchBody;
	const onlySelectedGeoFilters = getOnlySelectedGeo(filters, geo);
	const zooverFilters = initZooverCompositeSearchFilters(filters, onlySelectedGeoFilters);

	if (Object.keys(zooverFilters).length) {
		searchData.filters = { ...searchData.filters, ...zooverFilters };
	}

	if (filters.transport.length) {
		searchData.transport_type = filters.transport;
	}

	return searchData;
};

const initZooverCompositeSearchFilters = (
	filters: FilterState,
	onlySelectedGeoFilters: SelectedGeo,
): Search.ZooverCompositeFilters => {
	const zooverFilters: Search.ZooverCompositeFilters = {};

	if (filters.countries.length > 0) {
		zooverFilters.geo_filters = {
			...zooverFilters.geo_filters,
			country_ids: onlySelectedGeoFilters.countries.map((country) => country.entityId),
		};
	}
	if (filters.regions.length > 0) {
		zooverFilters.geo_filters = {
			...zooverFilters.geo_filters,
			region_ids: onlySelectedGeoFilters.regions.map((region) => region.entityId),
		};
	}
	if (filters.cities.length > 0) {
		zooverFilters.geo_filters = {
			...zooverFilters.geo_filters,
			city_ids: onlySelectedGeoFilters.cities.map((city) => city.entityId),
		};
	}

	if (filters.stars?.length) {
		zooverFilters.stars = filters.stars;
	}

	if (filters.accoType.length) {
		zooverFilters.accommodationType = filters.accoType;
	}

	if (filters.facilities.length) {
		zooverFilters.filters = filters.facilities;
	}

	if (filters.awards.length) {
		zooverFilters.awards = filters.awards;
	}

	if (filters.distances.length) {
		zooverFilters.distanceFilters = filters.distances;
	}

	if (filters.rating.length) {
		zooverFilters.rating = filters.rating;
	}

	if (filters.chains.length) {
		zooverFilters.chains = filters.chains.map((chain) => chain.entityId);
	}

	return zooverFilters;
};
