import PaymentMethodsResponse from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse';
import { useQuery } from '@tanstack/react-query';
import { getMinutesInMs } from '@vakantiesnl/services/src/util';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';

import { LOGGED_IN_QUERY_KEY } from './constants';

export function useGetBookingPaymentMethods(bookingSessionId: string): {
	paymentMethods: PaymentMethodsResponse | undefined;
	isLoadingPaymentMethods: boolean;
	hasPaymentMethodsError: boolean;
} {
	const query = useQuery({
		queryKey: [LOGGED_IN_QUERY_KEY, 'booking-payment-methods', bookingSessionId],
		queryFn: () => fetchBookingPaymentMethods(bookingSessionId),
		retry: false,
		staleTime: getMinutesInMs(15),
	});

	return {
		paymentMethods: query.data,
		isLoadingPaymentMethods: query.isLoading,
		hasPaymentMethodsError: query.isError,
	};
}

export async function fetchBookingPaymentMethods(bookingSessionId: string): Promise<PaymentMethodsResponse> {
	return fetchHandler({
		fetchFn: () => fetch(`${process.env.NEXT_PUBLIC_PAYMENT_ENDPOINT_URL}/api/v2/payment/${bookingSessionId}/list`),
		errorMessage: `Something went wrong fetching the payments methods for booking ${bookingSessionId}`,
	});
}
