import { useQuery } from '@tanstack/react-query';
import { LOGGED_IN_QUERY_KEY } from '@vakantiesnl/services/src/hooks/queries';
import { UserProfile } from '@vakantiesnl/services/src/user';

import { useApplicationStore } from '../../stores/applicationStore';
import { fetchHandler } from '../../util/fetchHandler';

type ProfileDataQuery = {
	userProfile: UserProfile | undefined;
	hasUserProfileError: boolean;
	isUserProfileLoading: boolean;
};

export const useGetUserProfile = (isEnabled = true): ProfileDataQuery => {
	const setUserId = useApplicationStore((s) => s.setUserId);

	const query = useQuery({
		queryKey: [LOGGED_IN_QUERY_KEY, 'user-profile'],
		queryFn: () => fetchUserProfile(setUserId),
		refetchInterval: false,
		staleTime: Infinity,
		refetchOnMount: 'always',
		enabled: isEnabled,
		retry: false,
	});

	return {
		userProfile: query.data,
		hasUserProfileError: query.isError,
		isUserProfileLoading: query.isLoading,
	};
};

async function fetchUserProfile(setUserId: (id: string) => void): Promise<UserProfile> {
	return await fetchHandler<UserProfile>({
		fetchFn: () => fetch('/api/user/profile'),
		errorMessage: 'Error fetching profile data',
		onStatusCodeError: (statusCode) => {
			if (statusCode === 401) {
				setUserId('');
			}
		},
	});
}
