/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { QueryClient, useQuery } from '@tanstack/react-query';
import { createRequestBySlug } from '@vakantiesnl/services/src/nbc';
import { mapFullAccommodation } from '@vakantiesnl/services/src/search/vaknl-mapper';
import { getMinutesInMs } from '@vakantiesnl/services/src/util';
import { StatusCodeError } from '@vakantiesnl/services/src/util/errorHandling';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { Nbc, Search } from '@vakantiesnl/types';
import { useRouter } from 'next/router';

function assertDefined(data?: Search.FullAccommodationItem): asserts data is Search.FullAccommodationItem {
	if (!data) {
		throw new TypeError('Error in useGetAccommodationByPath: no acco data found');
	}
}

export function useGetAccommodationByPath() {
	const router = useRouter();

	// Only fetch accommodation when on the acco page or when another API is used
	const isAccoPage = router.pathname === '/accommodation';

	const requestPath = getRequestPathFromAsPath(router.asPath);

	const query = useQuery({
		queryKey: [requestPath],
		staleTime: getMinutesInMs(15),
		queryFn: () => fetchAccommodationByPathData(requestPath, true),
		enabled: isAccoPage,
	});

	// For now we can assume we have the data, as there are no loading states yet fetching the acco details
	// We can assume the data is here as it is already fetched in the getInitialProps of this page
	assertDefined(query.data);

	return {
		accommodation: query.data,
	};
}

export function fetchAccommodationByPath(queryClient: QueryClient, asPath: string, enableQenner = false) {
	const requestPath = getRequestPathFromAsPath(asPath);

	return queryClient.fetchQuery({
		queryKey: [requestPath],
		queryFn: () => fetchAccommodationByPathData(requestPath, enableQenner),
		staleTime: getMinutesInMs(15),
	});
}

function getRequestPathFromAsPath(asPath: string) {
	return asPath.split(/\?|#|%/)[0].substring(1);
}

async function fetchAccommodationByPathData(requestPath: string, enableQenner = false) {
	/** Fetch UGC info when Brand is ZVRNL */
	const request = createRequestBySlug(requestPath, process.env.NEXT_PUBLIC_BRAND === 'ZVRNL');

	const nbc = await fetchHandler<Nbc.RawFullNbcItem>({
		fetchFn: () => fetchVakNL({ input: request }),
		errorMessage: 'Error fetching accommodation by path',
	});

	if (!nbc.accommodation) {
		throw new StatusCodeError(410);
	}
	nbc.accommodation.is_bookable_qenner = enableQenner ? nbc.accommodation.is_bookable_qenner : false;

	return mapFullAccommodation({
		name: nbc.accommodation?.name ?? '',
		giata_id: nbc.accommodation?.giata_id ?? 0,
		nbc,
	});
}
