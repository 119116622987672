import { useQuery } from '@tanstack/react-query';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { MicroCopy } from '@vakantiesnl/types/src';

import { useMicroCopyContext } from '../../context/microCopyContext';
import { RawCheckinSheet } from '../../schemas/user/checkinDataSchema';

export function useGetCheckinData(isEnabled: boolean): RawCheckinSheet | undefined {
	const checkinDataQuery = useQuery({
		queryKey: ['checkinData'],
		queryFn: () => fetchCheckinData(),
		staleTime: Infinity, // This sheet does not change often enough to refetch it during the client session.
		enabled: isEnabled,
	});

	return checkinDataQuery.isError ? [] : checkinDataQuery.data;
}

type BookingProps = {
	airlineCode: string;
	flightProvider: string | null;
	tourOperator: string;
};

export type CheckinEntree = {
	checkinAvailability: string;
	checkinTime?: string;
	checkinTimeAlternative?: string;
	checkinStep1?: string;
	checkinStep2?: string;
	checkinLink?: string;
};

export function useGetCheckinEntree(bookingProps: BookingProps): CheckinEntree | undefined {
	const checkinData = useGetCheckinData(true);
	const microcopy = useMicroCopyContext();

	return getMatchingEntree(checkinData, bookingProps, microcopy);
}

const tourOperatorsWithFlightProvider = ['ZVRVAK', 'VKTVAK'];

// Exported for testing purposes
export function getMatchingEntree(
	checkinData: RawCheckinSheet | undefined,
	{ airlineCode, flightProvider, tourOperator }: BookingProps,
	microcopy: MicroCopy,
): CheckinEntree | undefined {
	if (!checkinData) {
		return undefined;
	}

	const matchingEntree = checkinData.find((entree) => {
		if (tourOperatorsWithFlightProvider.includes(tourOperator)) {
			return (
				entree.Airline_Code === airlineCode &&
				entree.Flight_provider === flightProvider &&
				entree.Organisatie === tourOperator
			);
		}

		return entree.Airline_Code === airlineCode && entree.Organisatie === tourOperator;
	});

	if (!matchingEntree || !matchingEntree.Incheck) {
		return {
			checkinAvailability: microcopy['user.booking.checkin.notFoundAvailability'],
			checkinTime: microcopy['user.booking.checkin.notFoundTime'],
		};
	}

	return {
		checkinAvailability: matchingEntree.Incheck,
		checkinTime: matchingEntree.Inchecktijden,
		checkinTimeAlternative: matchingEntree.Anders,
		checkinStep1: matchingEntree.Hoe
			? matchingEntree.Hoe.replace(
					'<strong>%%=v(@flightServiceExternalRef)=%%</strong>',
					microcopy['user.booking.checkin.pnrText'],
				)
			: undefined,
		checkinStep2: matchingEntree.Uitleg,
		checkinLink: matchingEntree.Link_website,
	};
}

async function fetchCheckinData(): Promise<RawCheckinSheet> {
	return await fetchHandler({
		fetchFn: () =>
			fetch('/api/user/checkinData', {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			}),
		errorMessage: `Something went wrong retrieving the checkinData`,
	});
}
