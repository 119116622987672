import { keepPreviousData, useInfiniteQuery, QueryKey, InfiniteData } from '@tanstack/react-query';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { Search } from '@vakantiesnl/types/src';

import { useApplicationStore } from '../../stores/applicationStore';
import { getMinutesInMs } from '../../util';

export interface MappedFavoriteResponse {
	favorites: Search.CompactAccommodationItem[] | never[];
	pageParam: { limit: number; offset: number };
	total: number;
}

export const FAVORITE_PAGE_SIZE = 10;
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function useGetFavoriteData() {
	const favoriteItems = useApplicationStore((s) => s.favorites);

	const favoriteEntityIds = favoriteItems.map((favoriteItem) => favoriteItem.entityId);

	const query = useInfiniteQuery<
		MappedFavoriteResponse,
		Error,
		InfiniteData<MappedFavoriteResponse, MappedFavoriteResponse>,
		QueryKey,
		{ limit: number; offset: number }
	>({
		queryKey: ['favorite-query'],
		staleTime: getMinutesInMs(60),
		refetchOnMount: 'always',
		enabled: favoriteEntityIds.length > 0,
		queryFn: ({ pageParam: { offset, limit } }) => fetchFavoriteData(favoriteEntityIds, offset, limit),
		initialPageParam: {
			limit: 10,
			offset: 0,
		},
		getNextPageParam: (_x, _y, lastPageParam) => ({
			limit: lastPageParam.limit + FAVORITE_PAGE_SIZE,
			offset: lastPageParam.offset + FAVORITE_PAGE_SIZE,
		}),
		placeholderData: keepPreviousData,
	});

	return {
		favoriteAccommodations: query.data,
		isLoading: query.isLoading,
		fetchNextFavoritePage: query.fetchNextPage,
		total: favoriteItems.length,
	};
}

async function fetchFavoriteData(
	favoriteEntityIds: number[],
	offset: number,
	limit: number,
): Promise<MappedFavoriteResponse> {
	const favorites: Search.CompactAccommodationItem[] = await fetchHandler({
		fetchFn: () =>
			fetch(
				'/api/getFavoriteData',

				{
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ entityIds: favoriteEntityIds, offset, limit }),
				},
			),
		errorMessage: `Something went wrong during fetching the favorite data`,
	});

	return {
		favorites,
		pageParam: {
			offset,
			limit,
		},
		total: favoriteEntityIds.length,
	};
}
