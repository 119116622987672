import { useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTripFilterContext } from '@vakantiesnl/services/src/context/useTripFilterContext';
import { OfferResponse, useCreateRoomOffersRequest } from '@vakantiesnl/services/src/search';
import { mapCompactAccommodations } from '@vakantiesnl/services/src/search/vaknl-mapper';
import { isPackageOffer } from '@vakantiesnl/services/src/util';
import { errorHandler, parseErrorToReport } from '@vakantiesnl/services/src/util/errorHandling';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { OfferItem, RawParty } from '@vakantiesnl/types/src/search';

import { getSelectedOfferByRoomType } from './selectHelpers';
import { useAccoOfferStore } from '../../../stores/accommodationStore/useAccoStore';
import { useGetAccommodationByPath } from '../useGetAccommodationByPath';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useGetRoomTypeOffers() {
	const giataId = useGetAccommodationByPath().accommodation.giataId;
	const { state } = useTripFilterContext();

	const flightsOffer = useAccoOfferStore((s) => s.flightsOffer);
	const roomTypeOffer = useAccoOfferStore((s) => s.roomTypeOffer);
	const setRoomTypeOffer = useAccoOfferStore((s) => s.setRoomTypeOffer);

	const roomTypeRequestBody = useCreateRoomOffersRequest(flightsOffer);

	const query = useQuery({
		queryKey: ['roomType', flightsOffer?.id],
		queryFn: () => fetchRoomTypeOffers(giataId, roomTypeRequestBody, flightsOffer),
		enabled: !!flightsOffer,
	});

	const selected = useMemo(() => {
		if (!query.data?.all) {
			return undefined;
		}

		return getSelectedOfferByRoomType(state.selected.roomType, query.data?.all);
	}, [state.selected.roomType, query.data?.all]);

	// Update the store with the selected room type offer
	// Compare against previous value to prevent multiple executions in case this hook is called multiple times
	useEffect(() => {
		if (selected?.id !== roomTypeOffer?.id) {
			setRoomTypeOffer(selected);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected?.id]);

	return {
		isLoadingRoomTypeOffers: query.isLoading,
		roomTypeOffers: query.data,
	};
}

type RoomTypeOffersResponse = {
	all: OfferItem[];
	party: RawParty;
};

async function fetchRoomTypeOffers(
	giataId: number | undefined,
	requestBody: string,
	offer: OfferItem | undefined,
): Promise<RoomTypeOffersResponse | undefined> {
	if (!requestBody || !offer || !giataId) {
		return undefined;
	}

	try {
		const result = await fetchHandler<OfferResponse>({
			fetchFn: () =>
				fetchVakNL({
					input: new Request(
						`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/accommodation/${giataId}/offers?check_availability=true`,
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: requestBody,
						},
					),
				}),
			errorMessage: 'Cannot fetch room type offers',
		});

		const accommodations = mapCompactAccommodations(result.accommodations);
		const allOffers = accommodations?.length ? accommodations[0].offers.filter(isPackageOffer) : [];

		return {
			all: allOffers,
			party: result.party,
		};
	} catch (e) {
		errorHandler.report(parseErrorToReport(e, `Error in fetchRoomTypeOffers`));
		throw new Error('Error in fetchRoomTypeOffers' + e);
	}
}
