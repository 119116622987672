import { OfferItem } from '@vakantiesnl/types/src/search';

import { State } from '../../rest/trip-filters/reducer';

export function getSelectedOfferByFlight(
	selectedFlight: State['selected']['flight'],
	offers: OfferItem[],
	selectedOffer: OfferItem,
): OfferItem | undefined {
	if (!offers.length) {
		return undefined;
	}

	const matchedOffer =
		selectedFlight &&
		offers.find(
			({ outbound, inbound }) =>
				outbound.meta?.flightNumber === selectedFlight.outbound &&
				inbound.meta?.flightNumber === selectedFlight.inbound,
		);

	return matchedOffer || offers.find((o) => o.flightId === selectedOffer.flightId) || offers[0];
}

export function getSelectedOfferByRoomType(
	selectedRoomType: State['selected']['roomType'],
	offers: OfferItem[],
): OfferItem | undefined {
	if (!offers.length) {
		return undefined;
	}

	const matchedOffer =
		selectedRoomType && offers.find((room) => room.rooms.map((room) => room.opCode).join('_') === selectedRoomType);

	return matchedOffer || offers[0];
}

export function getSelectedOfferByMealplan(
	selectedMealplan: State['selected']['mealPlan'],
	offers: OfferItem[],
): OfferItem | undefined {
	if (!offers.length) {
		return undefined;
	}

	const matchedOffer = offers.find((mealPlan) => mealPlan.rooms[0].board.code === selectedMealplan);

	return matchedOffer || offers[0];
}
