import { useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTripFilterContext } from '@vakantiesnl/services/src/context/useTripFilterContext';
import { useGetAccommodationByPath } from '@vakantiesnl/services/src/hooks/queries';
import { OfferResponse, useCreateAccommodationOffersRequestBody } from '@vakantiesnl/services/src/search';
import { mapCompactAccommodations } from '@vakantiesnl/services/src/search/vaknl-mapper';
import { isPackageOffer } from '@vakantiesnl/services/src/util';
import { errorHandler, parseErrorToReport } from '@vakantiesnl/services/src/util/errorHandling';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { OfferItem, RawParty } from '@vakantiesnl/types/src/search';

import { useAccoOfferStore } from '../../../stores/accommodationStore/useAccoStore';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useGetDepartureDatePackageOffers() {
	const { state } = useTripFilterContext();

	const { accommodation } = useGetAccommodationByPath();
	const giataId = accommodation.giataId;

	const departureDateOffer = useAccoOfferStore((s) => s.departureDateOffer);
	const isDepartureDateLoading = useAccoOfferStore((s) => s.isDepartureDateLoading);
	const setDepartureDateOffer = useAccoOfferStore((s) => s.setDepartureDateOffer);
	const setIsDepartureDateLoading = useAccoOfferStore((s) => s.setIsDepartureDateLoading);

	const departureDateRequest = useCreateAccommodationOffersRequestBody();

	const query = useQuery({
		queryKey: ['departureDate', giataId, departureDateRequest],
		queryFn: () => fetchDepartureDateOffers(giataId, departureDateRequest),
		enabled: accommodation.isBookable,
	});

	const selectedDepartureDate = state.selected.date || state.departureDate?.[0];

	const selectedOffer = useMemo(() => {
		if (!query.data?.all.length) {
			/** Reset offer in the store because the useEffect does not always trigger */
			setDepartureDateOffer(undefined);
			return undefined;
		}

		let offer = query.data?.all.find((offer) => offer.meta?.departureDate === selectedDepartureDate);

		// When no offer is found and no departure date was selected, return the cheapest offer
		if (!offer && !selectedDepartureDate) {
			offer = query.data?.all.find((o) => o.id === query.data?.cheapest);
		}

		return offer;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query.data?.all]);

	// Update the store with the selected departure date offer
	// Compare against previous value to prevent multiple executions in case this hook is called multiple times
	useEffect(() => {
		if (selectedOffer?.id !== departureDateOffer?.id) {
			setDepartureDateOffer(selectedOffer);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOffer?.id]);

	useEffect(() => {
		if (query.isLoading !== isDepartureDateLoading) {
			setIsDepartureDateLoading(query.isLoading);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query.isLoading]);

	return {
		departureDateOffers: query.data,
	};
}

type DepartureDateOffersResponse = {
	min: number;
	max: number;
	cheapest: string;
	cheapestPricePerAdult: number;
	all: OfferItem[];
	party: RawParty;
};

async function fetchDepartureDateOffers(
	giataId: number | undefined,
	requestBody: string,
): Promise<DepartureDateOffersResponse | undefined> {
	if (!giataId) {
		return undefined;
	}

	try {
		const result = await fetchHandler<OfferResponse>({
			fetchFn: () =>
				fetchVakNL({
					input: new Request(
						`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/accommodation/${giataId}/offers?check_availability=true`,
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: requestBody,
						},
					),
				}),
			errorMessage: 'Cannot fetch departure date offers',
		});

		const accommodations = mapCompactAccommodations(result.accommodations);
		const allOffers = accommodations.length ? accommodations[0].offers.filter(isPackageOffer) : [];

		const prices = allOffers.map(({ priceTotal }) => priceTotal);
		const pricesPerAdult = allOffers.map(({ pricePerAdult }) => pricePerAdult);
		const [min, max] = [Math.min(...prices), Math.max(...prices)];
		const cheapestPricePerAdult = Math.min(...pricesPerAdult);
		const cheapest = allOffers.find(({ priceTotal }) => min === priceTotal);

		return {
			min,
			max,
			party: result.party,
			cheapest: cheapest?.id || '',
			cheapestPricePerAdult,
			all: allOffers,
		};
	} catch (e) {
		errorHandler.report(parseErrorToReport(e, `Error in fetchDepartureDateOffers`));
		throw new Error('Error in fetchDepartureDateOffers' + e);
	}
}
