import { useQuery } from '@tanstack/react-query';
import { LOGGED_IN_QUERY_KEY } from '@vakantiesnl/services/src/hooks/queries/constants';
import { createRequestByEntityId } from '@vakantiesnl/services/src/nbc';
import { mapFullAccommodation } from '@vakantiesnl/services/src/search/vaknl-mapper';
import { getMinutesInMs } from '@vakantiesnl/services/src/util';
import { isNotEmpty } from '@vakantiesnl/services/src/util/arrayUtils';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { Nbc } from '@vakantiesnl/types/src';

import { mapBookingsWithAccoDetails } from './helpers';
import { AccommodationDetailsResponse, UserBookings, UserBookingsResponse } from './interfaces';

export function useGetUserBookings(): UserBookings {
	const bookingsQuery = useQuery({
		queryKey: [LOGGED_IN_QUERY_KEY, 'user-bookings'],
		queryFn: fetchUserBookings,
	});

	const upcomingBookings = bookingsQuery.data?.upcomingBookings || [];
	const pastAndCancelledBookings = bookingsQuery.data?.pastAndCancelledBookings || [];

	// Use Set to remove any duplicate entityIds
	const entityIds = new Set([
		...upcomingBookings.map((b) => b.entityId),
		...pastAndCancelledBookings.map((b) => b.entityId),
	]);

	const nbcQuery = useQuery({
		queryKey: ['user-bookings-nbc-data', ...entityIds],
		queryFn: () => fetchAccommodationDetails([...entityIds]),
		enabled: entityIds.size > 0,
		staleTime: getMinutesInMs(15),
	});

	const upComingBookingsWithNbc = mapBookingsWithAccoDetails(upcomingBookings, nbcQuery.data);
	const pastAndCancelledBookingsWithNbc = mapBookingsWithAccoDetails(pastAndCancelledBookings, nbcQuery.data);

	return {
		upcomingBookings: upComingBookingsWithNbc,
		pastAndCancelledBookings: pastAndCancelledBookingsWithNbc,
		isLoadingBookings: bookingsQuery.isLoading || nbcQuery.isLoading,
		hasErrorBookings: bookingsQuery.isError || nbcQuery.isError,
	};
}

async function fetchUserBookings(): Promise<UserBookingsResponse> {
	return fetchHandler({
		fetchFn: () => fetch('/api/user/bookings'),
		errorMessage: 'Something went wrong fetching upcoming bookings',
	});
}

// exported for testing purposes
export async function fetchAccommodationDetails(entityIds: string[]): Promise<AccommodationDetailsResponse> {
	const results = await Promise.all(
		entityIds.map((entityId) => {
			return fetchHandler<Nbc.RawFullNbcItem>({
				fetchFn: () =>
					fetchVakNL({
						input: createRequestByEntityId(entityId, process.env.NEXT_PUBLIC_BRAND === 'ZVRNL', true),
					}),
				errorMessage: `Something went wrong fetching accommodation details for entityId ${entityId}`,
			}).catch(() => undefined);
		}),
	);

	return results.filter(isNotEmpty).reduce<AccommodationDetailsResponse>((prev, next) => {
		if (next.accommodation) {
			const mappedAccommodation = mapFullAccommodation({
				giata_id: next.accommodation.giata_id,
				name: next.accommodation.name,
				nbc: next,
			});

			prev[next.accommodation.entity_id] = mappedAccommodation;
		}

		return prev;
	}, {});
}
