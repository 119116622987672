import { useQuery } from '@tanstack/react-query';
import { getMinutesInMs } from '@vakantiesnl/services/src/util';

import { Airline, fetchAirlineEntree } from '../../contentful/user/airline';

type AirlineQuery = {
	airlineInfo: Airline | null;
	isLoadingAirlineInfo: boolean;
};

export function useGetAirline(airlineCode: string): AirlineQuery {
	const { data, isLoading } = useQuery({
		queryKey: ['user-booking-airline', airlineCode],
		queryFn: () =>
			fetchAirlineEntree({
				airlineCode: airlineCode.toUpperCase(),
			}),
		staleTime: getMinutesInMs(15),
	});

	return {
		airlineInfo: data || null,
		isLoadingAirlineInfo: isLoading,
	};
}
