/* istanbul ignore file */
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { errorHandler, parseErrorToReport } from '@vakantiesnl/services/src/util/errorHandling';
import { Search } from '@vakantiesnl/types/src';

import { createGeoContextRequest } from '../../search';
import { initBaseSearchReqParams } from '../../search/initSearchRequest';
import { mapGeoContext } from '../../search/mapper';
import { useFilterStore } from '../../stores/filtersStore';
import { useGlobalFiltersStore } from '../../stores/globalFiltersStore';
import { fetchVakNL } from '../../util/vaknl-fetch';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useGetGeoContext() {
	const filters = useFilterStore((s) => s.filters);
	const globalFilters = useGlobalFiltersStore((s) => s.filters);

	const body = initBaseSearchReqParams(filters, globalFilters);

	const query = useQuery({
		queryKey: ['geo-context', JSON.stringify(body)],
		queryFn: () => fetchGeoContext(body),
		placeholderData: keepPreviousData,
	});

	return {
		countrySlugsWithAccos: query.data?.countrySlugs ?? [],
		regionSlugsWithAccos: query.data?.regionSlugs ?? [],
		citySlugsWithAccos: query.data?.citySlugs ?? [],
	};
}

async function fetchGeoContext(body: Search.VaknlSearchBody): Promise<Search.GeoContext> {
	try {
		const rawGeoContext: Search.RawGeoContext = await fetchVakNL({
			input: createGeoContextRequest(body),
		}).then((response) => response.json());

		return mapGeoContext(rawGeoContext);
	} catch (e) {
		errorHandler.report(parseErrorToReport(e, 'Error fetching geoContext'));

		throw e;
	}
}
